import request from '@/utils/request'

// 创建支付订单
export function createOrder(data) {
  return request({
    url: `/api/v1/live/create-order`,
    method: 'POST',
    data
  })
}

// 查询订单支付状态
export function getOrderStatus(data) {
  return request({
    url: `/api/v1/live/query-order-status`,
    method: 'POST',
    data
  })
}

// 查询卡号对应appid
export function getCardAppid(data) {
  return request({
    url: `/api/v1/live/q-card-appid`,
    method: 'POST',
    data
  })
}


