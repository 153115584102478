<template>
  <el-container>
    <el-header>Header</el-header>
    <el-main>
      <div class="custom-container">
        <div class="left-container">
          <div class="icon-container">
            <div class="image-container">
              <img :src="require('@/assets/score.png')" alt="score" />
            </div>
            <div class="text-container">
              积分
            </div>
          </div>
          <div class="tip-container">
            <!-- <span style="margin-top: 10px;">1.换算规则：1元=1积分</span>
            <span style="margin-top: 10px;">2.积分将用于抵扣直播收到礼物的礼物</span>
            <span style="margin-top: 10px;">的抽成 (10%)比如你直播收到一个1$(按7￥算)</span>
            <span style="margin-top: 10px;">你充值了100￥(100个积分) 我们会自动扣除
              7￥0.1=0.7个积分</span>
            <span style="margin-top: 10px;">3.有问题请联系卡号提供者，他将和我们联系进行核对处理!</span> -->
            <el-alert title="积分充值说明" type="info" style="margin-top: 10px;height: 300px;" :closable="false">
              <p>1. 换算规则：1元=1积分</p>
              <p>2. 积分将用于抵扣直播收到礼物的抽成（10%）。
                比如你直播收到一个1$(按7￥算)，你充值了100￥（100个积分），
                我们会自动扣除 7￥×0.1=0.7个积分。</p>
              <p>3. 有问题请联系卡号提供者，他将和我们联系进行核对处理！</p>
            </el-alert>
          </div>
          <!-- <div style="line-height: 0px;margin-top: 60px;">
            <el-button>联系客服</el-button>
          </div> -->
          <!-- <div style="margin-top: 20px;">
            <img :src="require('@/assets/score.png')" width="200px" height="200px" alt="score" />
          </div> -->
        </div>
        <div class="divider"></div>
        <div class="right-container">
          <el-form ref="form" :model="form" label-width="100px" class="form-container">
            <el-form-item label="充值卡号：">
              <el-input v-model="form.cardNum" style="width: 300px;" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="充值数量：">
              <el-radio-group v-model="form.payNumType" @change="setChoosePrice">
                <el-radio-button :label="item.value" v-for="(item, index) in enums.payNum" :key="index">{{
                  item.label
                }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="自定义数量：" v-if="form.payNumType == 3">
              <el-input v-model="form.price" @input="handleInput" @change="generateQRCode"
                style="width: 300px;"></el-input>
            </el-form-item>
            <hr style="margin-top: 40px; margin-bottom: 40px;">
            <el-form-item label="支付方式：">
              <el-radio-group v-model="form.payType" @change="generateQRCode">
                <el-radio-button :label="item.value" v-for="(item, index) in enums.payType" :key="index">{{
                  item.label
                }}</el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item class="form-button-container">
              <div style="font-size: 18px;margin-left:60px;">
                您需支付<span style="color: red;font-size: 25px;">{{ form.price }}</span>元，请扫码支付！
              </div>
              <div style="margin-left: 25%;">
                <div id="qrcode" class="qrcode"></div>
                <div class="qrcode-loading" v-if="loading">
                  <div class="qrcode-loader" style="margin-top: 230px;margin-left: 50px;"></div>
                </div>
                <img :src="qrCodeData" alt="支付二维码" v-show="!loading">
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>

import enums from "@/utils/enums";
import QRCode from 'qrcode';
import { getCardAppid } from "@/api/common"
import { getCardAppid_hk } from "@/api/common_hk"

export default {
  name: 'IndexPage',
  components: {

  },
  data() {
    return {
      qrCodeData: '',
      loading: false, // 是否显示加载动画
      enums,
      form: {
        cardNum: '',
        platform: 6,
        payNumType: 0,
        price: 10,
        payType: 0,//0--微信 1--支付宝 2--财付通
      },
      appid: '',
    }
  },
  mounted() {
    this.getAppid();
  },

  created() {
    this.getCardInfo()
  },

  watch: {
    'form.price'() {
      this.generateQRCode();
    }
  },

  methods: {

    getCardInfo() {
      this.form.cardNum = this.$route.query.cardNum;
      this.form.platform = Number(this.$route.query.platform);

      //测试
      // this.form.platform = 7;
      // this.form.cardNum = 'ckri4tarat1qklolonn03f5d01';

      console.log("卡号：===", this.form.cardNum)
      console.log("平台：===", this.form.platform)
    },
    getAppid() {
      var data = {}
      data.cardNumber = this.form.cardNum
      data.platform = this.form.platform
      if (this.form.platform == 6) {
        getCardAppid(data).then(res => {
          if (res.code === 0) {
            this.appid = res.data.appIdF
            if (res.data.appId) {
              this.generateQRCode();
            }
          }
        })
      } else {
        getCardAppid_hk(data).then(res => {
          if (res.code === 0) {
            this.appid = res.data.appId
            if (res.data.appId) {
              this.generateQRCode();
            }
          }
        })
      }
    },

    handleInput() {
      if (this.form.price !== null) {
        let value = String(this.form.price).replace(/[^0-9]/g, ''); // 移除非数字字符
        if (value.length > 7) {
          value = value.slice(0, 7); // 只取前7位数
        }
        this.form.price = Number(value); // 将结果转为数值类型
      }
    },

    setChoosePrice() {
      switch (this.form.payNumType) {
        case 0:
          this.form.price = 10;
          break;
        case 1:
          this.form.price = 20;
          break;
        case 2:
          this.form.price = 60;
          break;

        default:
          break;
      }
      this.generateQRCode()
    },


    onSubmit() {
      console.log('submit!');
    },

    generateQRCode() {
      this.loading = true; // 显示加载动画

      const cardId = `cardId=${this.form.cardNum}`; //卡号
      const platform = `platform=${this.form.platform}`;//充值的平台，6--视频号  7--TK
      const scoreNum = `scoreNum=${this.form.price * 100}`; //金额
      const payType = `payType=${this.form.payType}`; //支付方式
      const appid = `appid=${this.appid}`
      // 支付界面链接
      //测试服
      const url = `https://test-wxpay.atomgameteam.com/#/payPage/?${appid}&${cardId}&${platform}&${scoreNum}&${payType}`;
      //正式服
      // const url = `https://wxpay.atomgameteam.com/#/payPage/?${appid}&${cardId}&${platform}&${scoreNum}&${payType}`;
      const redirect_encode_url = `redirect_uri=${encodeURIComponent(url)}`

      const paymentData = `https://open.weixin.qq.com/connect/oauth2/authorize?${appid}&${redirect_encode_url}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;

      QRCode.toDataURL(paymentData, { width: 200 }, (err, url) => {
        if (err) {
          console.error(err);
          return;
        }

        this.qrCodeData = url;

        setTimeout(() => {
          this.loading = false; // 隐藏加载动画
        }, 200); // 假设加载时间为0.2秒
      });
    },
  },
}

</script>

<style>
.el-header,
.el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 160px;
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
}

.custom-container {
  border: 1px solid #ccc;
  width: 1000px;
  height: 600px;
  background-color: #ffffff;
  display: flex;
  margin-top: 5%;
  margin-bottom: 5%;
}

.left-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.right-container {
  flex: 3;
  line-height: 0px;
  text-align: left;
}

.icon-container {
  display: flex;
  flex-direction: column;
  height: 200px;
  line-height: 0px;
}

.tip-container {
  display: flex;
  flex-direction: column;
  height: 300px;
  line-height: 20px;
  text-align: left;
  margin-left: 20px;
}

/* .tip-container span {
  display: flex;
  flex-direction: column;
  height: 15%;
  line-height: 20px;
  color: rgb(136, 134, 134);
  font-size: 14px;
} */


.image-container {
  margin-top: 20%;
  max-height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;
}

.text-container {
  margin-top: 10%;
  max-height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;
}

.form-container {
  width: 600px;
  height: 400px;
  margin-top: 40px;
  margin-left: 40px;
}

.form-button-container {
  display: flex;
  align-items: flex-end;
  margin-top: 0%;
  margin-left: 5%;
}

.divider {
  border-left: 1px solid #ccc;
  height: 100%;
  margin: 0 10px;
}

.qrcode-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.qrcode {
  width: 100%;
  height: 100%;
}

.qrcode-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.qrcode-loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #888;
  border-top-color: #333;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
