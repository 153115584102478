import {
  Enum
} from './Enum.js'

/**
 * 全局公共枚举类
 */
export default {
  //充值金额
  payNum: new Enum()
    .add('1', '10积分', 0)
    .add('2', '20积分', 1)
    .add('3', '60积分', 2)
    .add('4', '自定义数量', 3),

  //支付方式
  payType: new Enum()
    .add('0', '微信支付', 0),
  // .add('1', '支付宝', 1)
  // .add('2', '财付通', 2),

}
